$(function () {
  $('.conact-us').on('click', function () {
    window.open('https://a8-im.7x24cc.com/phone_webChat.html?accountId=N000000052349&chatId=cf36ab13-6d44-48c0-a9a1-05bd23b5fcab')
  })
  $('.cumServiceBtn').on('click', function () {
    let no = $(this).attr('data-no')
    console.log('no123', no)
    // return
    window.open('https://a8-im.7x24cc.com/phone_webChat.html?accountId=N000000052349&chatId=cf36ab13-6d44-48c0-a9a1-05bd23b5fcab?businessParam=' + no)
  })
})